import { injectQuery as __vite__injectQuery } from "/@vite/client";import.meta.env = {"BASE_URL": "/", "DEV": true, "MODE": "development", "PROD": false, "SSR": false};import RawTheme from "/.vitepress/theme/index.ts?t=1743670879971";
import { createApp as createClientApp, createSSRApp, defineComponent, h, onMounted, watchEffect } from "/.vitepress/cache/deps/vue.js?v=8d44a8c7";
import { ClientOnly } from "/node_modules/vitepress/dist/client/app/components/ClientOnly.js?v=8d44a8c7";
import { Content } from "/node_modules/vitepress/dist/client/app/components/Content.js?v=8d44a8c7";
import { useCodeGroups } from "/node_modules/vitepress/dist/client/app/composables/codeGroups.js?v=8d44a8c7";
import { useCopyCode } from "/node_modules/vitepress/dist/client/app/composables/copyCode.js?v=8d44a8c7";
import { useUpdateHead } from "/node_modules/vitepress/dist/client/app/composables/head.js?v=8d44a8c7";
import { usePrefetch } from "/node_modules/vitepress/dist/client/app/composables/preFetch.js?v=8d44a8c7";
import { dataSymbol, initData, siteDataRef, useData } from "/node_modules/vitepress/dist/client/app/data.js?v=8d44a8c7";
import { RouterSymbol, createRouter, scrollTo } from "/node_modules/vitepress/dist/client/app/router.js?v=8d44a8c7";
import { inBrowser, pathToFile } from "/node_modules/vitepress/dist/client/app/utils.js?v=8d44a8c7";
function resolveThemeExtends(theme) {
    if (theme.extends) {
        const base = resolveThemeExtends(theme.extends);
        return {
            ...base,
            ...theme,
            async enhanceApp(ctx) {
                if (base.enhanceApp)
                    await base.enhanceApp(ctx);
                if (theme.enhanceApp)
                    await theme.enhanceApp(ctx);
            }
        };
    }
    return theme;
}
const Theme = resolveThemeExtends(RawTheme);
const VitePressApp = defineComponent({
    name: 'VitePressApp',
    setup() {
        const { site, lang, dir } = useData();
        // change the language on the HTML element based on the current lang
        onMounted(() => {
            watchEffect(() => {
                document.documentElement.lang = lang.value;
                document.documentElement.dir = dir.value;
            });
        });
        if (import.meta.env.PROD && site.value.router.prefetchLinks) {
            // in prod mode, enable intersectionObserver based pre-fetch
            usePrefetch();
        }
        // setup global copy code handler
        useCopyCode();
        // setup global code groups handler
        useCodeGroups();
        if (Theme.setup)
            Theme.setup();
        return () => h(Theme.Layout);
    }
});
export async function createApp() {
    ;
    globalThis.__VITEPRESS__ = true;
    const router = newRouter();
    const app = newApp();
    app.provide(RouterSymbol, router);
    const data = initData(router.route);
    app.provide(dataSymbol, data);
    // install global components
    app.component('Content', Content);
    app.component('ClientOnly', ClientOnly);
    // expose $frontmatter & $params
    Object.defineProperties(app.config.globalProperties, {
        $frontmatter: {
            get() {
                return data.frontmatter.value;
            }
        },
        $params: {
            get() {
                return data.page.value.params;
            }
        }
    });
    if (Theme.enhanceApp) {
        await Theme.enhanceApp({
            app,
            router,
            siteData: siteDataRef
        });
    }
    // setup devtools in dev mode
    if (import.meta.env.DEV || __VUE_PROD_DEVTOOLS__) {
        import("/node_modules/vitepress/dist/client/app/devtools.js?v=8d44a8c7").then(({ setupDevtools }) => setupDevtools(app, router, data));
    }
    return { app, router, data };
}
function newApp() {
    return import.meta.env.PROD
        ? createSSRApp(VitePressApp)
        : createClientApp(VitePressApp);
}
function newRouter() {
    let isInitialPageLoad = inBrowser;
    return createRouter((path) => {
        let pageFilePath = pathToFile(path);
        let pageModule = null;
        if (pageFilePath) {
            // use lean build if this is the initial page load
            if (isInitialPageLoad) {
                pageFilePath = pageFilePath.replace(/\.js$/, '.lean.js');
            }
            if (import.meta.env.DEV) {
                pageModule = import(/*@vite-ignore*/ __vite__injectQuery(pageFilePath, 'import')).catch(() => {
                    // try with/without trailing slash
                    // in prod this is handled in src/client/app/utils.ts#pathToFile
                    const url = new URL(pageFilePath, 'http://a.com');
                    const path = (url.pathname.endsWith('/index.md')
                        ? url.pathname.slice(0, -9) + '.md'
                        : url.pathname.slice(0, -3) + '/index.md') +
                        url.search +
                        url.hash;
                    return import(/*@vite-ignore*/ __vite__injectQuery(path, 'import'));
                });
            }
            else {
                pageModule = import(/*@vite-ignore*/ __vite__injectQuery(pageFilePath, 'import'));
            }
        }
        if (inBrowser) {
            isInitialPageLoad = false;
        }
        return pageModule;
    }, Theme.NotFound);
}
if (inBrowser) {
    createApp().then(({ app, router, data }) => {
        // wait until page component is fetched before mounting
        router.go().then(() => {
            // dynamically update head tags
            useUpdateHead(router.route, data.site);
            app.mount('#app');
            // scroll to hash on new tab during dev
            if (import.meta.env.DEV && location.hash) {
                const target = document.getElementById(decodeURIComponent(location.hash).slice(1));
                if (target) {
                    scrollTo(target, location.hash);
                }
            }
        });
    });
}
